import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Picture } from 'react-responsive-picture'
import { HashLink } from 'react-router-hash-link'

const HeaderWrapper = styled.div`
    position: relative;
    user-select: none;

    .menu {
        width: 3rem;
        padding: 1rem;
        position: fixed;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 3;
        color: ${({ theme }) => theme.colors.text};
        background-color: ${({ $iconBGColor, theme }) =>
            $iconBGColor
                ? theme.colors.secondaryLightDimmed
                : theme.colors.primaryTap};
        cursor: pointer;
    }

    @media ${({ theme }) => theme.media.tabletPortrait} {
        .menu {
            width: 4rem;
        }
    }

    @media ${({ theme }) => theme.media.tabletLandscape} {
        height: 60vh;
    }

    @media ${({ theme }) => theme.media.laptop} {
        height: auto;
        background-color: ${({ theme }) => theme.colors.primary};
        box-shadow: ${({ theme }) => theme.shadows.small};
        display: flex;
        align-items: center;
    }
`

const LogoLink = styled(Link)`
    width: 100%;
    cursor: pointer;
    border: none;

    @media ${({ theme }) => theme.media.laptop} {
        width: 25%;
        padding: 2.5%;
    }
`

const Logo = styled(Picture)`
    width: 100%;
    height: 100%;
    display: block;
    box-shadow: ${({ theme }) => theme.shadows.small};

    @media ${({ theme }) => theme.media.tabletLandscape} {
        object-fit: cover;
    }

    @media ${({ theme }) => theme.media.laptop} {
        height: auto;
        box-shadow: none;
    }
`

const QuickQuote = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    height: 35%;
    font-size: 0.9rem;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        flex-direction: row;
        height: 25%;
        font-size: 1rem;
    }

    @media ${({ theme }) => theme.media.tabletLandscape} {
        font-size: 1.25rem;
    }
`

const QuickQuoteText = styled.p`
    margin-bottom: 0.5rem;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        margin-bottom: 0;
        margin-right: 3rem;
    }
`

const RoomWrapper = styled.span`
    width: 50%;
    display: flex;
    justify-content: space-between;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        width: initial;
        display: block;
    }
`

const RoomLink = styled(HashLink)`
    opacity: ${({ theme }) => theme.opacity.dimmed};
    transition: opacity ${({ theme }) => theme.transitions.small};

    &:hover,
    &:active {
        opacity: ${({ theme }) => theme.opacity.full};
    }

    @media ${({ theme }) => theme.media.tabletPortrait} {
        margin-right: 3rem;
    }
`

export {
    HeaderWrapper,
    LogoLink,
    Logo,
    QuickQuote,
    QuickQuoteText,
    RoomWrapper,
    RoomLink
}
