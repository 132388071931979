import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const RouteIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .down {
        position: relative;
        padding: none;
        width: 2rem;
        padding: 0.5rem;
        cursor: pointer;
    }
`

const RouterLink = styled(Link)`
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    border: none;
`

const SubList = styled(motion.ul)`
    position: relative;
    margin: 1rem 0;
    padding: 1rem 0;
    background-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: inset ${({ theme }) => theme.shadows.medium};

    @media ${({ theme }) => theme.media.tabletPortrait} {
        padding: 1.5rem 0;
    }
`

const Spacer = styled.hr`
    width: 60%;
    border-color: ${({ theme }) => theme.colors.primaryDarkDisabled};
    opacity: ${({ theme }) => theme.opacity.dimmed};
`

const SubListItem = styled.li`
    font-size: 1.25rem;
    padding: 0.25rem 0;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 1.5rem;
        padding: 0.5rem 0;
    }

    @media ${({ theme }) => theme.media.laptop} {
        font-size: 1.8rem;
    }
`

const StyledHashLink = styled(HashLink)`
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
`

export {
    RouteIconWrapper,
    RouterLink,
    SubList,
    Spacer,
    SubListItem,
    StyledHashLink
}
