import React, { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Up } from '../SVG/Up'
import { Wrapper } from './ScrollTopButton.styled'
import { scrollUp } from '../../functions/ScrollUp'
import { InUpOutDownSmall } from '../../animation/animations'

const ScrollTopButton = () => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const updateState = () => {
            if (window.scrollY > 80) {
                setVisible(true)
            } else {
                setVisible(false)
            }
        }

        window.addEventListener('scroll', updateState)

        return () => {
            window.removeEventListener('scroll', updateState)
        }
    }, [])

    return (
        <AnimatePresence>
            {visible && (
                <Wrapper onClick={() => scrollUp(true)} {...InUpOutDownSmall}>
                    <Up />
                </Wrapper>
            )}
        </AnimatePresence>
    )
}
export { ScrollTopButton }
