import React from 'react'

import { routes } from '../../Routes/routes'
import {
    NavWrapper,
    NavList,
    ListLink,
    ListItem,
    Spacer
} from './NavDesktop.styled'

const NavDesktop = () => (
    <NavWrapper>
        <NavList>
            {routes.map(
                ({ path, $label }) =>
                    path !== undefined &&
                    path !== '/' && (
                        <React.Fragment key={path}>
                            <ListItem>
                                <ListLink to={path}>{$label}</ListLink>
                            </ListItem>
                            {$label !== 'contact us' && <Spacer />}
                        </React.Fragment>
                    )
            )}
        </NavList>
    </NavWrapper>
)

export { NavDesktop }
