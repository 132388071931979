import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { AnimatePresence } from 'framer-motion'

import { Theme } from '../../assets/styles/Theme'
import { Menu } from '../SVG/Menu'
import { Nav } from '../Nav/Nav'
import { NavDesktop } from '../NavDesktop/NavDesktop'
import {
    HeaderWrapper,
    LogoLink,
    Logo,
    QuickQuote,
    QuickQuoteText,
    RoomWrapper,
    RoomLink
} from './Header.styled'
import { sources } from './Header.sources'

const Header = () => {
    const [mobileNavOpen, setMobileNavOpen] = useState(false)

    const isAtLeastLaptop = useMediaQuery({
        query: Theme.media.laptop
    })

    return (
        <HeaderWrapper $iconBGColor={mobileNavOpen}>
            <LogoLink to="/">
                <Logo sources={sources} alt="The vision furniture logo" />
            </LogoLink>
            {isAtLeastLaptop ? (
                <NavDesktop />
            ) : (
                <>
                    <Menu
                        isOpen={mobileNavOpen}
                        click={() => {
                            setMobileNavOpen(navOpen => !navOpen)
                        }}
                    />
                    <AnimatePresence>
                        {mobileNavOpen && <Nav setNavOpen={setMobileNavOpen} />}
                    </AnimatePresence>
                </>
            )}
            <QuickQuote>
                <QuickQuoteText>
                    Get a quick online quote today for your dream:
                </QuickQuoteText>
                <RoomWrapper>
                    <RoomLink smooth to="/bedroom/#estimate">
                        Bedroom
                    </RoomLink>
                    <RoomLink smooth to="/livingroom/#estimate">
                        Living Room
                    </RoomLink>
                </RoomWrapper>
            </QuickQuote>
        </HeaderWrapper>
    )
}

export { Header }
