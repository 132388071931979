import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Down } from '../SVG/Down'
import {
    RouteIconWrapper,
    RouterLink,
    SubList,
    Spacer,
    SubListItem,
    StyledHashLink
} from './RouteSubList.styled'
import { InDownOutUpMedium } from '../../animation/animations'
import { scrollUp } from '../../functions/ScrollUp'

const RouteSubList = ({ path, $label, $subList, setNavOpen }) => {
    const [subListOpen, setSubListOpen] = useState(false)
    const navChange = () => setNavOpen(navOpen => !navOpen)

    return (
        <>
            {$subList ? (
                <>
                    <RouteIconWrapper>
                        <RouterLink
                            to={path}
                            onClick={() => {
                                navChange()
                                scrollUp()
                            }}
                        >
                            {$label}
                        </RouterLink>
                        <Down
                            click={() => {
                                setSubListOpen(open => !open)
                            }}
                        />
                    </RouteIconWrapper>
                    <AnimatePresence>
                        {subListOpen && (
                            <SubList key={path} {...InDownOutUpMedium}>
                                {$subList.map(({ label, hash }, index) => (
                                    <React.Fragment key={path + hash}>
                                        {index !== 0 && <Spacer />}
                                        <StyledHashLink
                                            smooth
                                            to={path + hash}
                                            onClick={navChange}
                                        >
                                            <SubListItem>{label}</SubListItem>
                                        </StyledHashLink>
                                    </React.Fragment>
                                ))}
                            </SubList>
                        )}
                    </AnimatePresence>
                </>
            ) : (
                <RouterLink
                    to={path}
                    onClick={() => {
                        navChange()
                        scrollUp()
                    }}
                >
                    {$label}
                </RouterLink>
            )}
        </>
    )
}

export { RouteSubList }
