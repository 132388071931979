import { keyframes } from 'styled-components'

const Theme = {
    animations: {
        bounce: keyframes`
            0%, 100% {
                transform: translate3d(0, -25%, 0);
                transition: cubic-bezier(0.8, 0, 1, 1);
            }
            50% {
                transform: translate3d(0, 0, 0);
                transition: cubic-bezier(0, 0, 0.2, 1);
            }
        `
    },

    colors: {
        primary: 'rgb(160,174,161)',
        primaryTap: 'rgba(160, 174, 161, 0.8)',
        primaryDisabled: 'rgba(160, 174, 161, 0.38)',
        primaryLight: 'rgb(209,224,210)',
        primaryLightDisabled: 'rgba(209, 224, 210, 0.38)',
        primaryDark: 'rgb(113,127,114)',
        primaryDarkDimmed: 'rgba(113, 127, 114, 0.8)',
        primaryDarkDisabled: 'rgba(113, 127, 114, 0.38)',
        secondary: 'rgb(161,160,174)',
        secondaryLight: 'rgb(210,209,224)',
        secondaryLightDimmed: 'rgba(210,209,224,0.8)',
        secondaryDark: 'rgb(114,114,127)',
        secondaryDarkDimmed: 'rgba(114,114,127,0.8)',
        text: 'rgb(0,0,0)',
        surface: 'rgb(255,255,255)',
        error: 'rgb(244,67,54)',
        warning: 'rgb(255, 204, 0)',
        info: 'rgb(33,150,243)',
        success: 'rgb(76,175,80)'
    },

    fonts: {
        serif: `'Cinzel', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif`,
        sansSerif: `'Montserrat', Arial, Helvetica,Arial, Helvetica, sans-serif`
    },

    opacity: {
        full: 1,
        dimmed: 0.8,
        disabled: 0.38
    },

    rounded: '5px',

    shadows: {
        small:
            '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        medium:
            '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)',
        large:
            '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)'
    },

    transitions: {
        small: '100ms cubic-bezier(0.4, 0, 0.2, 1)',
        mediumIn: '250ms cubic-bezier(0, 0, 0.2, 1)',
        mediumOut: '200ms cubic-bezier(0.4, 0, 1, 1)',
        largeIn: '300ms cubic-bezier(0, 0, 0.2, 1)',
        largeOut: '250ms cubic-bezier(0.4, 0, 1, 1)'
    },

    media: {
        mobile: '(min-width: 414px)',
        tabletPortrait: '(min-width: 768px)',
        tabletLandscape: '(min-width: 1024px)',
        laptop: '(min-width: 1366px)',
        desktop: '(min-width: 1920px)'
    },

    mixins: {
        flexColumn: `
            display: flex;
            flex-direction: column;
        `,
        flexCenter: `
            display: flex;
            justify-content: center;
            align-items: center;
        `,
        flexCenterColumn: `
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        `,
        flexJustifyCenter: `
            display: flex;
            justify-content: center;
        `,
        flexJustifyCenterColumn: `
            display: flex;
            justify-content: center;
            flex-direction: column;
        `,
        flexAlignCenter: `
            display: flex;
            align-items: center;
        `,
        flexAlignCenterColumn: `
            display: flex;
            align-items: center;
            flex-direction: column;
        `,
        noTapHighlight: `
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
        `
    }
}

export { Theme }
