import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

const Wrapper = styled(motion.div)`
    width: 3rem;
    height: 3rem;
    position: fixed;
    z-index: 1;
    bottom: 0;
    right: 2rem;
    background: ${({ theme }) => theme.colors.secondaryDarkDimmed};
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    color: white;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        width: 4rem;
        height: 4rem;
    }
`

export { Wrapper }
