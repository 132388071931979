import { speed } from './speed'
import { easing } from './easing'

const { small, mediumIn, mediumOut, largeIn, largeOut } = speed
const { easeIn, easeOut } = easing

const InRightOutLeftLarge = {
    initial: { opacity: 0.2, x: -30 },
    animate: {
        opacity: 1,
        x: 0,
        transition: { duration: largeIn, ease: easeIn }
    },
    exit: {
        opacity: 0.2,
        x: 30,
        transition: { duration: largeOut, ease: easeOut }
    }
}

const InRightOutLeftLMedium = {
    initial: { opacity: 0.2, x: -30 },
    animate: {
        opacity: 1,
        x: 0,
        transition: { duration: mediumIn, ease: easeIn }
    },
    exit: {
        opacity: 0.2,
        x: 30,
        transition: { duration: mediumOut, ease: easeOut }
    }
}

const InLeftOutLeftLarge = {
    initial: { opacity: 0.2, x: 30 },
    animate: {
        opacity: 1,
        x: 0,
        transition: { duration: largeIn, ease: easeIn }
    },
    exit: {
        opacity: 0.2,
        x: 30,
        transition: { duration: largeOut, ease: easeOut }
    }
}

const HeroContent = inView => ({
    animate: {
        opacity: inView ? 1 : 0,
        y: inView ? 0 : -30,
        transition: { duration: mediumIn, ease: easeIn }
    }
})

const InUpOutDownSmall = {
    initial: { opacity: 0.2, y: 30 },
    animate: {
        opacity: 1,
        y: 0,
        transition: { duration: small, ease: easeIn }
    },
    exit: {
        opacity: 0.2,
        y: 30,
        transition: { duration: small, ease: easeOut }
    }
}

const InDownOutUpMedium = {
    initial: { opacity: 0.2, y: -30 },
    animate: {
        opacity: 1,
        y: 0,
        transition: { duration: mediumIn, ease: easeIn }
    },
    exit: {
        opacity: 0.2,
        y: -30,
        transition: { duration: mediumOut, ease: easeOut }
    }
}

const ProductImage = (inView, index) => ({
    animate: {
        opacity: inView ? 1 : 0,
        y: inView ? 0 : -30,
        transition: { duration: mediumIn, ease: easeIn, delay: index * 0.2 }
    }
})

const InDownLarge = {
    initial: { opacity: 0, y: -30 },
    animate: {
        opacity: 1,
        y: 0,
        transition: { duration: largeIn, ease: easeIn }
    }
}

const fadeInOut = {
    initial: { opacity: 0 },
    animate: {
        opacity: 1,
        transition: { duration: largeIn, ease: easeIn }
    },
    exit: {
        opacity: 0,
        transition: { duration: largeOut, ease: easeOut }
    }
}

export {
    InRightOutLeftLarge,
    InRightOutLeftLMedium,
    InLeftOutLeftLarge,
    HeroContent,
    InUpOutDownSmall,
    InDownOutUpMedium,
    ProductImage,
    InDownLarge,
    fadeInOut
}
