import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../Routes/routes'
import { ContactInfo } from './ContactInfo'
import {
    FooterWrapper,
    LinksRow,
    LogoColumn,
    Logo,
    LinksColumn,
    LinksList,
    ListItem,
    InternalLink,
    ExternalLink,
    SocialContainer,
    SocialLink,
    SocialImage,
    FooterBottom,
    CreatorLink
} from './Footer.styled'
import { scrollUp } from '../../functions/ScrollUp'
import { logoSources, checkatradeSources } from './Footer.sources'

const Footer = () => (
    <FooterWrapper>
        <LinksRow>
            <LogoColumn>
                <InternalLink to="/" onClick={() => scrollUp(false)}>
                    <Logo
                        sources={logoSources}
                        alt="The vision furniture icon logo"
                    />
                </InternalLink>
            </LogoColumn>
            <LinksColumn>
                <LinksList>
                    {routes.map(
                        ({ path, $label }) =>
                            path !== undefined && (
                                <React.Fragment key={path}>
                                    <ListItem>
                                        <Link
                                            to={path}
                                            onClick={() => scrollUp(false)}
                                        >
                                            {$label}
                                        </Link>
                                    </ListItem>
                                </React.Fragment>
                            )
                    )}
                    <ListItem>
                        <ExternalLink href="./sitemap.xml">
                            sitemap
                        </ExternalLink>
                    </ListItem>
                </LinksList>
            </LinksColumn>
        </LinksRow>
        <SocialContainer>
            <SocialLink
                href="https://www.instagram.com/mark_visionfurniture"
                target="_blank"
                rel="noopener noreferrer"
            >
                <SocialImage
                    src="./images/instagram.svg"
                    alt="The official Instagram logo"
                />
            </SocialLink>
            <SocialLink
                href="https://www.checkatrade.com/trades/VisionFurniture"
                target="_blank"
                rel="noopener noreferrer"
            >
                <SocialImage
                    sources={checkatradeSources}
                    alt="The official Checkatrade logo"
                />
            </SocialLink>
        </SocialContainer>

        <FooterBottom>
            <CreatorLink
                href="https://www.linkedin.com/in/timothy-dunphy-575b541a9/"
                target="_blank"
                rel="noopener"
            >
                Site designed and built by Tim Dunphy
            </CreatorLink>
            <ContactInfo />
        </FooterBottom>
    </FooterWrapper>
)

export { Footer }
