import React from 'react'
import loadable from '@loadable/component'

import { bedroomData, livingRoomData } from '../productData/productData'
import {
    bedroomReviews,
    livingRoomReviews,
    kitchenReviews,
    homepageReviews
} from '../assets/reviews'

const LoadableHome = loadable(() =>
    import(/* webpackChunkName: "Home" */ '../views/Home/Home')
)
const LoadableProductPage = loadable(() =>
    import(
        /* webpackChunkName: "ProductPage" */ '../views/ProductPage/ProductPage'
    )
)
const LoadableContactUs = loadable(() =>
    import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs/ContactUs')
)
const LoadableFourZeroFour = loadable(() =>
    import(/* webpackChunkName: "404" */ '../views/404/404')
)

const livingRoomPhotos = require
    .context(`../../public/images/media-alcove-units`, true, /\.jpg|\.webp$/)
    .keys()
    .reduce((acc, curr) => {
        const dirName = curr.split('/')[1]
        const imagePath = `./images/media-alcove-units/${curr.substring(2)}`
        acc[dirName] === undefined
            ? (acc[dirName] = [imagePath])
            : (acc[dirName] = [...acc[dirName], imagePath])
        return acc
    }, {})

const bedroomPhotos = require
    .context(`../../public/images/wardrobes`, true, /\.jpg|\.webp$/)
    .keys()
    .reduce((acc, curr) => {
        const dirName = curr.split('/')[1]
        const imagePath = `./images/wardrobes/${curr.substring(2)}`
        acc[dirName] === undefined
            ? (acc[dirName] = [imagePath])
            : (acc[dirName] = [...acc[dirName], imagePath])
        return acc
    }, {})

const kitchenPhotos = require
    .context(`../../public/images/kitchens`, true, /\.jpg|\.webp$/)
    .keys()
    .reduce((acc, curr) => {
        const dirName = curr.split('/')[1]
        const imagePath = `./images/kitchens/${curr.substring(2)}`
        acc[dirName] === undefined
            ? (acc[dirName] = [imagePath])
            : (acc[dirName] = [...acc[dirName], imagePath])
        return acc
    }, {})

const routes = [
    {
        path: '/',
        $label: 'home',
        $spacer: true,
        exact: true,
        render: () => (
            <LoadableHome
                reviews={homepageReviews}
                title="Vision Furniture | Fitted Wardrobes &amp; Bespoke Design"
                description="Here at Vision Furniture we offer a unique service whereby all of our stunning collection of fitted wardrobes, bespoke living room cabinetry and tailored designs throughout you’re home are all created in house by our expert team, bringing a personal touch all the way through our projects."
            />
        )
    },
    {
        path: '/bedroom/',
        $label: 'the bedroom',
        $spacer: true,
        $subList: [
            { label: 'See the gallery', hash: '#gallery' },
            { label: 'See the reviews', hash: '#reviews' },
            { label: 'Get an online quote', hash: '#estimate' }
        ],
        exact: true,
        render: props => (
            <LoadableProductPage
                {...props}
                productData={bedroomData}
                reviews={bedroomReviews}
                photos={bedroomPhotos}
                title="The Bedroom"
                description="With a wide variety of options on our bedroom furniture, we can create your favourite styles in any colour to compliment your room design, making use of every inch available with our fully bespoke designs."
                introText="Browse our gallery of fully bespoke, fitted wardrobes and see which design, colour or style will work best in your home. At Vision Furniture, Mark and the team can create any combination that's unique for you!"
                calculator={true}
                contactFormSubject="Bedroom Enquiry"
                contactFormTemplateID="product_template"
            />
        )
    },
    {
        path: '/livingroom/',
        $label: 'the living room',
        $spacer: true,
        $subList: [
            { label: 'See the gallery', hash: '#gallery' },
            { label: 'See the reviews', hash: '#reviews' },
            { label: 'Get an online quote', hash: '#estimate' }
        ],
        exact: true,
        render: props => (
            <LoadableProductPage
                {...props}
                productData={livingRoomData}
                reviews={livingRoomReviews}
                photos={livingRoomPhotos}
                title="The Living Room"
                description="With a wide variety of options on our fitted wardrobes, we can create your favourite styles in any colour to compliment your room design, making use of every inch available with our fully bespoke designs."
                introText="Our wide range of living room products can be tailor made to you. From bespoke Media units, Alcove cabinets, fitted bookcases and many more designs, take a look at our gallery and see how we can improve your home!"
                calculator={true}
                contactFormSubject="Living Room Enquiry"
                contactFormTemplateID="product_template"
            />
        )
    },
    {
        path: '/kitchen/',
        $label: 'the kitchen',
        $spacer: true,
        $subList: [
            { label: 'See the gallery', hash: '#gallery' },
            { label: 'See the reviews', hash: '#reviews' }
            // { label: 'Get an estimate', hash: '#estimate' }
        ],
        exact: true,
        render: props => (
            <LoadableProductPage
                {...props}
                // productData={kitchenData}
                reviews={kitchenReviews}
                photos={kitchenPhotos}
                title="The Kitchen"
                description="Shaker kitchens that are handmade in England using authentic Shaker style Kitchen cabinets. Bespoke design and high quality make these kitchens shine above the rest."
                introText="Our shaker style kitchens are hand crafted and tailor made for you. Great care and attention to detail goes into every unit Vision Furniture makes, to ensure that your new kitchen will feel as good as it looks!"
                calculator={false}
                contactFormSubject="Kitchen Enquiry"
                contactFormTemplateID="product_template"
            />
        )
    },
    {
        path: '/contact-us/',
        $label: 'contact us',
        $spacer: false,
        exact: true,
        component: LoadableContactUs
    },
    {
        component: LoadableFourZeroFour
    }
]

export { routes }
