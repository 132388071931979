import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Picture } from 'react-responsive-picture'

const FooterWrapper = styled.footer`
    padding-top: 3rem;
    background: ${props => props.theme.colors.primary};
    box-shadow: ${({ theme }) => theme.shadows.small};
`

const LinksRow = styled.div`
    display: flex;
`

const CommonColumn = css`
    width: 50%;
    ${props => props.theme.mixins.flexCenterColumn};
    margin-bottom: 1rem;
`

const LogoColumn = styled.div`
    ${CommonColumn}
    border-right: 1px solid
    ${props => props.theme.colors.primaryDarkDisabled};
`

const Logo = styled(Picture)`
    width: 80%;
    padding: 10%;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        width: 100%;
        padding: 0;
    }
`

const LinksColumn = styled.div`
    ${CommonColumn}
`

const LinksList = styled.ul`
    list-style: none;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`

const ListItem = styled.li`
    font-family: ${props => props.theme.fonts.sansSerif};
    text-transform: uppercase;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 1.25rem;
    }

    @media ${({ theme }) => theme.media.laptop} {
        font-size: 1.5rem;
    }
`

const InternalLink = styled(Link)`
    text-align: center;
    border: none;
`

const ExternalLink = styled.a``

const SocialContainer = styled.div`
    width: 100%;
    padding-top: 1rem;
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid ${props => props.theme.colors.primaryDarkDisabled};

    @media ${({ theme }) => theme.media.tabletPortrait} {
        padding-top: 1.5rem;
    }
`

const SocialLink = styled.a`
    ${({ theme }) => theme.mixins.flexCenter}
    border: none;
`

const SocialImage = styled(Picture)`
    height: 3rem;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        height: 5rem;
    }
`

const FooterBottom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1.5rem;

    @media ${({ theme }) => theme.media.tabletLandscape} {
        flex-direction: row;
    }
`

const CreatorLink = styled.a`
    font-size: smaller;
    margin-bottom: 2rem;

    @media ${({ theme }) => theme.media.tabletLandscape} {
        margin-bottom: initial;
    }

    @media ${({ theme }) => theme.media.laptop} {
        font-size: initial;
    }
`

export {
    FooterWrapper,
    LinksRow,
    LogoColumn,
    Logo,
    LinksColumn,
    LinksList,
    ListItem,
    InternalLink,
    ExternalLink,
    SocialContainer,
    SocialLink,
    SocialImage,
    FooterBottom,
    CreatorLink
}
