import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    html {
            background-color: #e6e6e6;

            @media ${({ theme }) => theme.media.laptop} {
                ${({ theme }) => theme.mixins.flexCenterColumn};
            }
        }

    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
        -webkit-tap-highlight-color: ${({ theme }) => theme.colors.primaryTap};
        background: ${({ theme }) => theme.colors.primaryLightDisabled};
        box-shadow: ${({ theme }) => theme.shadows.large};
        overflow-x: hidden;

        @media ${({ theme }) => theme.media.laptop} {
            width: 85vw;
        }
    }

    body, h1, h2, h3, h4, h5, h6, p, input, ul, li, em {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
    }

    h1 { font-size: 2rem; }
    h2 { font-size: 1.5rem; }
    h3 { font-size: 1.17rem; }
    h4 { font-size: 1.12rem; }
    h5 { font-size: .83rem; }
    h6 { font-size: .75rem; }

    @media ${({ theme }) => theme.media.laptop} {
        h1 { font-size: 2.4rem; }
        h2 { font-size: 1.8rem; }
        h3 { font-size: 1.404rem; }
        h4 { font-size: 1.344rem; }
        h5 { font-size: .996rem; }
        h6 { font-size: .9rem; }
    }

    h1, h2, h3, h4, h5, h6, nav {
        font-family: ${({ theme }) => theme.fonts.serif};
        text-transform: uppercase;
    }

    a {
        color: ${({ theme }) => theme.colors.text};
        line-height: 48px;
        text-decoration: none;
        border-style: solid;
        border-width: 0px;
        border-bottom-width: 1px;
        padding-bottom: 0.125rem;
        cursor: pointer;
    }

    a, p, input, textarea, em, small {
        font-family: ${({ theme }) => theme.fonts.sansSerif};
    }

    @media ${({ theme }) => theme.media.laptop} {
        input, textarea {
            font-size: 1.2rem;
        }
        small {
            font-size: 0.96rem;
        }
    }

    code {
        font-family: source-code-pro, monospace;
    }

    // Gallery zoom buttons
    .iiz__btn {
        width: 3rem;
        height: 3rem;

        &:before {
            width: 1.5rem;
            height: 1.5rem;
        }

        @media ${({ theme }) => theme.media.tabletPortrait} {
            width: 4rem;
            height: 4rem;

            &:before {
                width: 2rem;
                height: 2rem;
            }
        }
    }
`
export { GlobalStyles }
