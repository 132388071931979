import { Theme } from '../../assets/styles/Theme'
const { media } = Theme
const { tabletPortrait, tabletLandscape, laptop } = media

const sources = [
    {
        srcSet: './images/header-logo/vision-furniture-logo-laptop.webp',
        type: 'image/webp',
        media: laptop
    },
    {
        srcSet: './images/header-logo/vision-furniture-logo-laptop.jpg',
        type: 'image/jpg',
        media: laptop
    },
    {
        srcSet:
            './images/header-logo/vision-furniture-logo-tablet-landscape.webp',
        type: 'image/webp',
        media: tabletLandscape
    },
    {
        srcSet:
            './images/header-logo/vision-furniture-logo-tablet-landscape.jpg',
        type: 'image/jpg',
        media: tabletLandscape
    },
    {
        srcSet:
            './images/header-logo/vision-furniture-logo-tablet-portrait.webp',
        type: 'image/webp',
        media: tabletPortrait
    },
    {
        srcSet:
            './images/header-logo/vision-furniture-logo-tablet-portrait.jpg',
        type: 'image/jpg',
        media: tabletPortrait
    },
    {
        srcSet: './images/header-logo/vision-furniture-logo-mobile.webp',
        type: 'image/webp'
    },
    {
        srcSet: './images/header-logo/vision-furniture-logo-mobile.jpg',
        type: 'image/jpg'
    }
]

export { sources }
