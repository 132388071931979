import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

const NavWrapper = styled.nav`
    width: 65%;
    height: 100%;
    padding: 2.5%;
`

const NavList = styled.ul`
    ${({ theme }) => theme.mixins.flexAlignCenter};
    justify-content: space-evenly;
    list-style: none;
    text-align: center;
`

const ListLink = styled(Link)`
    border: none;
`

const ListItem = styled.li`
    font-size: 1.5rem;
    padding: 0 1rem;

    @media ${({ theme }) => theme.media.desktop} {
        font-size: 2rem;
    }
`

const Spacer = styled.li`
    height: 2rem;
    border-right: 1px solid black;
`

export { NavWrapper, NavList, ListLink, ListItem, Spacer }
