import React from 'react'

import { routes } from '../../Routes/routes'
import { RouteSubList } from './RouteSubList'
import { BodyFixed, NavWrapper, NavList, ListItem, Spacer } from './Nav.styled'
import { InLeftOutLeftLarge } from '../../animation/animations'

const Nav = ({ setNavOpen }) => (
    <>
        <BodyFixed />
        <NavWrapper {...InLeftOutLeftLarge}>
            <NavList>
                {routes.map(
                    ({ path, $label, $subList, $spacer }) =>
                        path !== undefined && (
                            <React.Fragment key={path}>
                                <ListItem>
                                    <RouteSubList
                                        path={path}
                                        $label={$label}
                                        $subList={$subList}
                                        setNavOpen={setNavOpen}
                                    />
                                </ListItem>
                                {$spacer && <Spacer />}
                            </React.Fragment>
                        )
                )}
            </NavList>
        </NavWrapper>
    </>
)

export { Nav }
