import { Theme } from '../../assets/styles/Theme'
const { media } = Theme
const { tabletPortrait } = media

const logoSources = [
    {
        srcSet: './images/footer-logo/vision-furniture-icon-laptop.webp',
        type: 'image/webp',
        media: tabletPortrait
    },
    {
        srcSet: './images/footer-logo/vision-furniture-icon-laptop.jpg',
        type: 'image/jpg',
        media: tabletPortrait
    },
    {
        srcSet: './images/footer-logo/vision-furniture-icon-mobile.webp',
        type: 'image/webp'
    },
    {
        srcSet: './images/footer-logo/vision-furniture-icon-mobile.jpg',
        type: 'image/jpg'
    }
]

const checkatradeSources = [
    {
        srcSet: './images/checkatrade.webp',
        type: 'image/webp'
    },
    {
        srcSet: './images/checkatrade.png',
        type: 'image/png'
    }
]

export { logoSources, checkatradeSources }
