const bedroomReviews = [
    {
        review:
            'Our new fitted wardrobes are fantastic - Mark has done a brilliant job! The finish is extremely neat and we are very happy with the finished result. It was an awkward sloping loft room, but he has made sure that every inch of space can be used for storage which is what we hoped for. Would definitely use Mark again.',
        date: 'September 2020'
    },
    {
        review:
            'We are delighted with the furniture Mark has made for our bedroom. He took our ideas and came up with a fabulous design. The furniture looks great and is incredibly well made. I wouldn’t hesitate to recommend him. He is a cheerful and friendly person and it has been a pleasure dealing with him.',
        date: 'March 2020'
    },
    {
        review:
            'I cannot recommend Mark highly enough! From design to installation Mark’s professionalism and enthusiasm shone through. Attention to detail was meticulous - he is clearly a highly skilled carpenter and his craftsmanship was excellent. I will definitely be recommending Mark to everyone I know!',
        date: 'May 2019'
    },
    {
        review:
            'Mark is such an enthusiastic, friendly and pleasant chap. From the first meeting for the quote to the actual build day, he was always on time, fully explained what he was doing and worked methodically throughout. The finished wardrobes are absolutely superb and a top end product. I am absolutely delighted with them!',
        date: 'March 2019'
    },
    {
        review:
            'Mark was a pleasure to work with, he is very pleasing and likeable and cannot do enough for you as a customer. He visited us twice with regards to design and preferences throughout the process. The work was high quality, clean & tidy, I would highly recommend Mark and would definitely use him for any further work.',
        date: 'October 2018'
    }
]

const livingRoomReviews = [
    {
        review:
            'We are absolutely delighted with the work completed, from the help given with design and practicalities, to the finished product.',
        date: 'August 2020'
    },
    {
        review:
            "We can't thank Mark enough for the media unit he built for us! We would without hesitation recommend Mark to anyone. Thank you mark.",
        date: 'September 2019'
    },
    {
        review:
            'We are very pleased with our bespoke unit. Mark was very friendly, diligent, and worked very hard. It had completely transformed our room.',
        date: 'September 2019'
    },
    {
        review:
            'Very pleased with both the design that Mark developed from our ideas and the quality of the finished work. It has been much admired by friends and neighbours.',
        date: 'March 2019'
    },
    {
        review:
            "Mark is enthusiastic and friendly. He asked the right questions and furnished practical ideas that we had not considered. We wouldn't hesitate to recommend him.",
        date: 'January 2019'
    }
]

const kitchenReviews = [
    {
        review:
            'Excellent attention to detail and very courteous. The standard of workmanship was also to a very high standard and we are more than pleased wit the results. We could recommend Mark to anyone looking to improve their home - a very talented and personable guy.',
        date: 'February 2018'
    },
    {
        review:
            'The best. Top quality, professional, attentive to detail. Great communication. High quality of workmanship.',
        date: 'November 2020'
    },
    {
        review:
            'Mark is a pleasure to work with and is driven by pleasing the customer. I am delighted with the work he has done for me and will definitely be using him for future work. I can wholeheartedly recommend him.',
        date: 'November 2017'
    },
    {
        review:
            "We've been really impressed with Mark from start to finish. He was flexible in coming to visit and measure up and provided a timely and fair quote afterwards. Throughout the time we've been in contact, Mark has been able to answer any questions and he has been really proactive with ideas, using his experience to help us get the most from the work. The work he's done looks excellent and he was very tidy. I fully recommend his work and would look to use Mark again in the future if we needed any more work done.",
        date: 'June 2015'
    },
    {
        review:
            'Mark provides valuable advice alongside great craftsmanship. The service he provides is absolutely fantastic and very professional. The design and install was perfect.',
        date: 'March 2015'
    }
]

const homepageReviews = [
    {
        review:
            'Mark and Gary have done a brilliant job. I am absolutely delighted with my wardrobes. Both were extremely professional, courteous and worked diligently to get the job done to my liking. Would most definitely, without hesitation, recommend both Mark and Gary. Thank you both.',
        date: 'December 2020'
    },
    {
        review:
            'Mark was really professional and also approachable in the planning for the cupboards. His team hand made our bespoke cupboards, fitted them and painted them all to a high standard. We are so pleased with the result. We would definitely recommend Mark and his team!',
        date: 'December 2020'
    },
    {
        review:
            'Our new fitted wardrobes are fantastic - Mark has done a brilliant job! The finish is extremely neat and we are very happy with the result. It was an awkward sloping loft room, but he has made sure that every inch of space can be used for storage. Would definitely use Mark again for carpentry work.',
        date: 'September 2019'
    },
    {
        review:
            'We are absolutely delighted with the work completed, from the help given with design and practicalities, to the finished product. The work exceeded our expectations! Mark was great to communicate with and did everything possible to ensure we got the design that was right for us.',
        date: 'August 2019'
    },
    {
        review:
            'I am thrilled with my new wardrobe. Mark picked up what I wanted to achieve and gave me ideas. The quality of his work is excellent and he was reliable and cleared up after. I am planning another project for later this year and will use him. I have no hesitation in recommending Mark for a bespoke project.',
        date: 'June 2019'
    }
]

export { bedroomReviews, livingRoomReviews, kitchenReviews, homepageReviews }
