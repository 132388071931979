import standardMDFUnpainted from './imgs/standard-mdf-unpainted.jpg'
import standardMDFPainted from './imgs/standard-mdf-painted.jpg'
import whiteMDF from './imgs/white-mdf.jpg'

const bedroomData = [
    {
        calculatorIndex: 0,
        meterageLabel: 'Linear meterage of storage required',
        min: 0,
        meterageMax: 10,
        drawersMax: 20,
        step: 0.5,
        finishLabel: 'Wardrobe finish',
        group: 'wardrobe',
        finishes: {
            standardMDFUnpainted: {
                id: 'standard-mdf-unpainted',
                label: 'MDF Interiors & Unpainted Exteriors',
                firstMeter: 1200,
                multiplier: 400,
                drawers: 125,
                image: standardMDFUnpainted
            },
            whiteMDF: {
                id: 'white-mdf',
                label: 'Finished Interiors & Hand Painted Exteriors',
                firstMeter: 1500,
                multiplier: 1200,
                drawers: 125,
                image: whiteMDF
            }
        }
    }
]

const livingRoomData = [
    {
        calculatorIndex: 0,
        meterageLabel: 'Linear meterage of storage required',
        min: 0,
        meterageMax: 10,
        drawersMax: 20,
        step: 0.5,
        finishLabel: 'Unit finish',
        group: 'unit',
        finishes: {
            standardMDFUnpainted: {
                id: 'standard-mdf-unpainted',
                label: 'MDF Interiors & Unpainted Exteriors',
                firstMeter: 1000,
                multiplier: 500,
                drawers: 125,
                image: standardMDFUnpainted
            },
            standardMDFPainted: {
                id: 'standard-mdf-painted',
                label: 'MDF Interiors & Hand Painted Exteriors',
                firstMeter: 1375,
                multiplier: 875,
                drawers: 125,
                image: standardMDFPainted
            },
            whiteMDF: {
                id: 'white-mdf',
                label: 'Finished Interiors & Hand Painted Exteriors',
                firstMeter: 1000,
                multiplier: 875,
                drawers: 125,
                image: whiteMDF
            }
        }
    }
]

export { bedroomData, livingRoomData }
