import 'react-app-polyfill/stable'
import smoothscroll from 'smoothscroll-polyfill'

import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { Theme } from './assets/styles/Theme'
import { GlobalStyles } from './assets/styles/GlobalStyles'
import { Header } from './components/Header/Header'
import { Router } from './Routes/Router'
import { Footer } from './components/Footer/Footer'
import { ScrollTopButton } from './components/ScrollTopButton/ScrollTopButton'

smoothscroll.polyfill()

const rootElement = document.getElementById('root')
const App = (
    <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <BrowserRouter>
            <Header />
            <Router />
            <Footer />
            <ScrollTopButton />
        </BrowserRouter>
    </ThemeProvider>
)

rootElement.hasChildNodes()
    ? hydrate(App, rootElement)
    : render(App, rootElement)
