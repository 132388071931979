import styled from 'styled-components'

const Wrapper = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        flex-direction: row;
    }
`

const Icon = styled.svg`
    width: 2rem;
    margin-bottom: 1rem;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        margin-right: 0.5rem;
        margin-bottom: initial;
    }
`

const Text = styled.p`
    margin-bottom: 2rem;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        margin-right: 1.5rem;
        margin-bottom: initial;
    }
`

const EmailText = styled.a`
    margin-bottom: 2rem;
    line-height: initial;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        margin-right: 1.5rem;
        margin-bottom: initial;
    }
`

export { Wrapper, Icon, Text, EmailText }
