import styled, { createGlobalStyle } from 'styled-components/macro'
import { motion } from 'framer-motion'

const BodyFixed = createGlobalStyle`
    body {
        overflow: hidden;
    }
`

const NavWrapper = styled(motion.nav)`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.secondaryLight};
    box-shadow: ${({ theme }) => theme.shadows.medium};
    overflow-y: auto;

    @media ${({ theme }) => theme.media.laptop} {
        width: 80vw;
        margin: 0 10vw;
    }
`

const NavList = styled.ul`
    min-height: 100%;
    margin: 0 0 4.7rem 0;
    list-style: none;
    ${({ theme }) => theme.mixins.flexJustifyCenterColumn};
    text-align: center;

    @media ${({ theme }) => theme.media.laptop} {
        margin: 0;
    }
`

const ListItem = styled.li`
    font-size: 1.5rem;
    margin: 1.5rem 0;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 2rem;
        margin: 2rem 0;
    }

    @media ${({ theme }) => theme.media.laptop} {
        font-size: 2.4rem;
    }
`

const Spacer = styled.hr`
    width: 80%;
    border-color: ${({ theme }) => theme.colors.primaryDarkDisabled};

    @media ${({ theme }) => theme.media.tabletPortrait} {
        width: 60%;
    }
`

export { BodyFixed, NavWrapper, NavList, ListItem, Spacer }
